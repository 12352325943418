import * as Types from '../index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserChannelsQueryVariables = Types.Exact<{
  searchWord?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetUserChannelsQuery = { __typename?: 'Query', userChannels: Array<{ __typename?: 'ChannelEntity', id: string, createAt: any, updateAt: any, description?: string | null, Messages?: Array<{ __typename?: 'MessageEntity', id: string, text?: string | null, userId: string, createAt: any, updateAt: any, readDate?: any | null, channelId: string, attachment?: string | null, isSms: boolean, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, createAt: any, updateAt: any, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, createAt?: any | null, updateAt?: any | null, birthday?: any | null, lastName: string, firstName: string } | null } | null }> | null, ChannelMembers?: Array<{ __typename?: 'ChannelMemberEntity', id: string, userId: string, createAt: any, channelId: string, lastConnection: any, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, createAt: any, updateAt: any, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, createAt?: any | null, updateAt?: any | null, birthday?: any | null, lastName: string, firstName: string, profilePhoto?: string | null, Phones?: Array<{ __typename?: 'PhonesEntity', id: string, type: Types.PhoneType, value: string, createAt: any, updateAt: any }> | null } | null, FirmAccount?: Array<{ __typename?: 'FirmAccountEntity', id: string, name?: string | null, Interviews?: Array<{ __typename?: 'InterviewEntity', id: string, name: string, TaxYearInterview?: Array<{ __typename?: 'TaxYearInterviewEntity', id: string, taxYearId: string, TaxYear?: { __typename?: 'TaxYearEntity', year: number } | null }> | null }> | null }> | null } | null }> | null }> };

export type GetChannelMessagesQueryVariables = Types.Exact<{
  channelId: Types.Scalars['String'];
  take?: Types.InputMaybe<Types.Scalars['Float']>;
  skip?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type GetChannelMessagesQuery = { __typename?: 'Query', getChannelMessages: Array<{ __typename?: 'MessageEntity', id: string, text?: string | null, userId: string, createAt: any, updateAt: any, readDate?: any | null, channelId: string, attachment?: string | null, isSms: boolean, Users?: { __typename?: 'UserEntity', id: string, email?: string | null, createAt: any, updateAt: any, languageId?: string | null, UserInformation?: { __typename?: 'UserInformationEntity', id: string, userId?: string | null, createAt?: any | null, updateAt?: any | null, birthday?: any | null, lastName: string, firstName: string } | null } | null }> };

export type UserMeetingQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type UserMeetingQuery = { __typename?: 'Query', UserMeeting: { __typename?: 'MeetingEntity', id: string, channelId: string, Events?: { __typename?: 'EventEntity', id: string, status: Types.EventStatuses, startDate: any, endDate: any, EventTypes: { __typename?: 'EventTypeEntity', id: string, beforeBuffer?: number | null, afterBuffer?: number | null } } | null, MeetingAttendees: Array<{ __typename?: 'MeetingAttendeesEntity', id: string, userId?: string | null, unregisteredUserId?: string | null, firstName?: string | null, lastName?: string | null, profilePhoto?: string | null }>, Channels?: { __typename?: 'ChannelEntity', id: string, Messages?: Array<{ __typename?: 'MessageEntity', id: string, userId: string, attachment?: string | null, text?: string | null, readDate?: any | null, createAt: any, isSms: boolean }> | null } | null } };


export const GetUserChannelsDocument = gql`
    query getUserChannels($searchWord: String) {
  userChannels(searchWord: $searchWord) {
    id
    createAt
    updateAt
    description
    Messages {
      id
      text
      userId
      createAt
      updateAt
      readDate
      channelId
      attachment
      isSms
      Users {
        id
        email
        createAt
        updateAt
        languageId
        UserInformation {
          id
          userId
          createAt
          updateAt
          birthday
          lastName
          firstName
        }
      }
    }
    ChannelMembers {
      id
      userId
      createAt
      channelId
      lastConnection
      Users {
        id
        email
        createAt
        updateAt
        languageId
        UserInformation {
          id
          userId
          createAt
          updateAt
          birthday
          lastName
          firstName
          profilePhoto
          Phones {
            id
            type
            value
            createAt
            updateAt
          }
        }
        FirmAccount {
          id
          name
          Interviews {
            id
            name
            TaxYearInterview {
              id
              taxYearId
              TaxYear {
                year
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserChannelsQuery__
 *
 * To run a query within a React component, call `useGetUserChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserChannelsQuery({
 *   variables: {
 *      searchWord: // value for 'searchWord'
 *   },
 * });
 */
export function useGetUserChannelsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserChannelsQuery, GetUserChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserChannelsQuery, GetUserChannelsQueryVariables>(GetUserChannelsDocument, options);
      }
export function useGetUserChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserChannelsQuery, GetUserChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserChannelsQuery, GetUserChannelsQueryVariables>(GetUserChannelsDocument, options);
        }
export type GetUserChannelsQueryHookResult = ReturnType<typeof useGetUserChannelsQuery>;
export type GetUserChannelsLazyQueryHookResult = ReturnType<typeof useGetUserChannelsLazyQuery>;
export type GetUserChannelsQueryResult = Apollo.QueryResult<GetUserChannelsQuery, GetUserChannelsQueryVariables>;
export const GetChannelMessagesDocument = gql`
    query GetChannelMessages($channelId: String!, $take: Float, $skip: Float) {
  getChannelMessages(channelId: $channelId, take: $take, skip: $skip) {
    id
    text
    userId
    createAt
    updateAt
    readDate
    channelId
    attachment
    isSms
    Users {
      id
      email
      createAt
      updateAt
      languageId
      UserInformation {
        id
        userId
        createAt
        updateAt
        birthday
        lastName
        firstName
      }
    }
  }
}
    `;

/**
 * __useGetChannelMessagesQuery__
 *
 * To run a query within a React component, call `useGetChannelMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelMessagesQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetChannelMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetChannelMessagesQuery, GetChannelMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelMessagesQuery, GetChannelMessagesQueryVariables>(GetChannelMessagesDocument, options);
      }
export function useGetChannelMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelMessagesQuery, GetChannelMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelMessagesQuery, GetChannelMessagesQueryVariables>(GetChannelMessagesDocument, options);
        }
export type GetChannelMessagesQueryHookResult = ReturnType<typeof useGetChannelMessagesQuery>;
export type GetChannelMessagesLazyQueryHookResult = ReturnType<typeof useGetChannelMessagesLazyQuery>;
export type GetChannelMessagesQueryResult = Apollo.QueryResult<GetChannelMessagesQuery, GetChannelMessagesQueryVariables>;
export const UserMeetingDocument = gql`
    query UserMeeting($id: String!) {
  UserMeeting(id: $id) {
    id
    channelId
    Events {
      id
      status
      startDate
      endDate
      EventTypes {
        id
        beforeBuffer
        afterBuffer
      }
    }
    MeetingAttendees {
      id
      userId
      unregisteredUserId
      firstName
      lastName
      profilePhoto
    }
    Channels {
      id
      Messages {
        id
        userId
        attachment
        text
        readDate
        createAt
        isSms
      }
    }
  }
}
    `;

/**
 * __useUserMeetingQuery__
 *
 * To run a query within a React component, call `useUserMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMeetingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserMeetingQuery(baseOptions: Apollo.QueryHookOptions<UserMeetingQuery, UserMeetingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserMeetingQuery, UserMeetingQueryVariables>(UserMeetingDocument, options);
      }
export function useUserMeetingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMeetingQuery, UserMeetingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserMeetingQuery, UserMeetingQueryVariables>(UserMeetingDocument, options);
        }
export type UserMeetingQueryHookResult = ReturnType<typeof useUserMeetingQuery>;
export type UserMeetingLazyQueryHookResult = ReturnType<typeof useUserMeetingLazyQuery>;
export type UserMeetingQueryResult = Apollo.QueryResult<UserMeetingQuery, UserMeetingQueryVariables>;
export const namedOperations = {
  Query: {
    getUserChannels: 'getUserChannels',
    GetChannelMessages: 'GetChannelMessages',
    UserMeeting: 'UserMeeting'
  }
}